import React from "react";
import * as authService from "../../../services/authService";
const MyAccount = ({ history }) => {
  React.useEffect(() => {
    authService
      .logout()
      .then((response) => {
        localStorage.clear();
        history.push("/login");
      })
      .catch((error) => {
        localStorage.clear();
        history.push("/login");
      });
  }, []);
  return <div></div>;
};

export default MyAccount;
