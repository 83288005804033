import { createStore } from "redux";

const initialState = {
  sidebarShow: "responsive",
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  newBuilding: null
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "user":
      return { ...state, ...rest };
    case "set_building":
      return { ...state, ...rest }
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
