import axios from "axios";
import { BASE_URL } from "./base_url";

export function login(data) {
  return axios.post(BASE_URL + "login", data);
}

export function register(data) {
  return axios.post(BASE_URL + "register", data);
}

export function forgotPassword(data) {
  return axios.post(BASE_URL + "forgot-password", data);
}

export function resetPassword(id, data) {
  return axios.post(BASE_URL + "reset-password/" + id, data);
}

export function accountUpdate(data) {
  return axios.put(BASE_URL + "account-update", data);
}

export function verifyEmail(path) {
  let url = new URL(path);
  let verifyUrl = url.pathname + url.search;

  verifyUrl = "http://localhost" + verifyUrl;


  if(process.env.REACT_APP_ENV === 'prod') {
    verifyUrl = "https://loop.l7energy.com" + verifyUrl;
  }
  else if (process.env.REACT_APP_ENV === 'dev') {
    verifyUrl = "http://dev-loop.l7energy.com" + verifyUrl;
  }

  console.log("Auth service verifyUrl - " + verifyUrl);

  return axios.get(verifyUrl);
}

export function resendEmail(data) {
  return axios.post(BASE_URL + "email/resend", data);
}

export function logout() {
  return axios.post(BASE_URL + "logout");
}
