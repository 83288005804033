import React, { Component } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { PrivateRoute } from "./PrivateRoute";
import { history } from "./services/history";
import MyAccount from "./views/pages/my_account/MyAccount";
const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(() => import("./views/pages/forgot_password/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./views/pages/forgot_password/ResetPassword"));
const VerifyEmailPage = React.lazy(() => import("./views/pages/forgot_password/VerifyEmailPage"));
const InviteUserPage = React.lazy(() => import("./views/pages/users/InviteUserPage"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <BrowserRouter history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path='/login' name='Login Page' render={(props) => <Login {...props} />} />
            <Route exact path='/forgot-password' name='ForgotPassword Page' render={(props) => <ForgotPassword {...props} />} />
            <Route name='ForgotPassword Page' exact path='/reset-password/:id' render={(props) => <ResetPassword {...props} />} />
            <Route exact path='/verify-email' component={VerifyEmailPage} />
            <Route exact path='/invite' component={InviteUserPage} />
            <Route exact path='/register' name='Register Page' render={(props) => <Register {...props} />} />
            <Route exact path='/404' name='Page 404' render={(props) => <Page404 {...props} />} />
            <Route exact path='/500' name='Page 500' render={(props) => <Page500 {...props} />} />
            <Route exact path='/logout' component={MyAccount} />
            <PrivateRoute
              path='/'
              name='Home'
              component={TheLayout}
              // render={(props) => <TheLayout {...props} />}
              roles={["Admin", "Manager", "Engineer", "Consultant", "Client"]}
            />
            {/* <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            /> */}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
