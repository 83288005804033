let api_url = "http://localhost:8000/v1/api/";

if (process.env.REACT_APP_ENV === "prod") {
    api_url = "https://loop.l7energy.com/v1/api/";
} else if (process.env.REACT_APP_ENV === "dev"){
    api_url = "http://dev-loop.l7energy.com/v1/api/";
}

console.log("baseUrl - NODE_ENV = " + process.env.NODE_ENV);
console.log("baseUrl - REACT_APP_ENV = " + process.env.REACT_APP_ENV);
console.log("baseUrl - api_url = " + api_url);

export const BASE_URL = api_url;
